
import { useDispatch } from "react-redux";
import Papa from 'papaparse'
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import ImportModule from "../../tools/components/general/ImportModule";
import { addRequestStatus, setLoaderStatus } from "../../store/global/actions.global";
import { import_dext } from "./importacd.utils";
import { ServerProps, server_host } from "../../config/server.config";
import { useState } from "react";
import DownloadFileDeposit from "../file_deposit/layouts/DownloadFileDeposit";


export interface ImportTpACDProps extends ServerProps {

}


interface ImportTpACDManager {
    mode : "import" | "export", 
    link? : string
}

export default function ImportTpACD(props: ImportTpACDProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch();
    const [manager, setManager] = useState<ImportTpACDManager>({mode : "import"})


    const handleDownload = () => { 
        const link = document.createElement('a');
        console.log(manager)
        link.href = server_host(manager.link?? '');
        link.download = 'Export.zip'; // Nom du fichier à télécharger
        link.target = '_blank'; // O

        // Ajouter le lien au DOM et le déclencher
        document.body.appendChild(link);
        link.click();

        // Supprimer le lien du DOM une fois le téléchargement terminé
        document.body.removeChild(link);

        dispatch(setLoaderStatus({status : false}))
    }


    // * COMPONENT CONFIG 
    const import_module = {
        subtitle : "de type fichier Tatiana Payet (.csv)",
        nameClass : "export-doc__import-module",
        handleSubmit : (file:File) => {
            if(file) {
                const mimetype_accepted = [
                    'text/csv'
                ]

                if(!mimetype_accepted.includes(file.type)) {
                    return dispatch(addRequestStatus({
                        status : false, 
                        message : "Le fichier importé n'est pas un fichier CSV"
                    }))
                }


                if(Object.entries(file).length > 0) {
                    dispatch(setLoaderStatus({status : true, message : ""}))

                    const reader: FileReader = new FileReader();

                    reader.onload = (event: ProgressEvent<FileReader>) => {
                        if (!event.target || !event.target.result) return;
                        //dispatch(setLoaderStatus({status : true}))
                        const arrayBuffer: ArrayBuffer = event.target.result as ArrayBuffer;
                        const dataView: DataView = new DataView(arrayBuffer);
                        const decoder: TextDecoder = new TextDecoder('utf-8');
                        const csvText: string = decoder.decode(dataView);

                        Papa.parse(csvText, {
                            complete: (results: any) => {
                                props.server.post('/import-tp-acd', {data : results.data})
                                .then(res => {
                                    setManager({mode : "export", link : res.data.data})
                                    dispatch(addRequestStatus(res.data))
                                    dispatch(setLoaderStatus({status : false, message : ""}))
                                })
                                .catch(err => {
                                    dispatch(addRequestStatus(err.response.data))
                                    dispatch(setLoaderStatus({status : false, message : ""}))
                                })

                            },
                        });
                    };
                    
                    reader.readAsArrayBuffer(file);
        
                }
            }
        }
    }
    return (
        <BaseApp>
            <BaseContainer nameClass = "export-doc">
                {
                    manager.mode === "import" ? 
                        <ImportModule {...import_module}/> 
                    : 
                        <DownloadFileDeposit 
                            handleDownload = {handleDownload}
                            handleNewDeposit = {() => setManager({mode : "import"})}
                        />
                }
            </BaseContainer>
        </BaseApp>
    )
}