

type SaleModel = {
    name : string, 
    text : string, 

}

export const models:SaleModel[] = [
    {
        name : "altes", 
        text : "Altes",
    }, 
    {
        name : "needle-island", 
        text : "Needle Island",
    }, 
    {
        name : "escale-sommeil", 
        text : "Escale sommeil",
    }, 
    {
        name : "les-ptits-anges", 
        text : "Les p'tits anges",
    }, 
    {
        name : "ter", 
        text : "TER",
    }

]