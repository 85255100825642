import React from 'react' ; 
import { Axios } from 'axios';
import { AuthType } from '../../store/types.store';
import Homepage from '../base/Homepage';
import Clients from '../clients/Clients';
import FileDeposit from '../file_deposit/FileDeposit';
import Documents from '../documents/Documents';
import ExportDoc from '../export/ExportDoc';
import ImportACD from '../import-acd/ImportACD';
import GenerateSales from '../generate-sales/GenerateSales';
import ImportTpACD from '../tp-import-acd/ImportACD';


export interface NavigationProps {
    server : Axios
}

export interface NavigationParams extends NavigationProps {
    account : AuthType
}

type NavigationItem = {
    id : string,
    id_menu : number,
    content : string,
    svg : string,
    path : string,
    component ?: JSX.Element
}

export const navigation = (props:NavigationProps):NavigationItem[] => {

    return [
        {id : 'N-00', id_menu : 1, content : "Tableau de bord", svg : "home", path : '/', component : <Homepage />}, 
        {id : 'N-01', id_menu : 2, content : "Clients",  svg : 'users', path : '/clients', component : <Clients {...props} />}, 
        {id : 'N-02', id_menu : 2, content : "Dépôt de fichiers",  svg : 'upload', path : '/deposit', component : <FileDeposit {...props} />}, 
        {id : 'N-03', id_menu : 3, content : "Documents",  svg : 'folder', path : '/folders', component : <Documents {...props} />}, 
        {id : 'N-04', id_menu : 4, content : "Exports documents",  svg : 'export', path : '/export', component : <ExportDoc {...props} />}, 
        {id : 'N-05', id_menu : 5, content : "Préparation import ACD",  svg : 'file-signature', path : '/import-acd', component : <ImportACD {...props} />},
        {id : 'N-06', id_menu : 6, content : "Écritures de ventes",  svg : 'file-invoice-dollar', path : '/generate-sales', component : <GenerateSales {...props} />}, 
        {id : 'N-07', id_menu : 7, content : "TP export personnalisé",  svg : 'file-signature', path : '/import-tp-acd', component : <ImportTpACD {...props} />}, 
    ]
}

export const navigation_data = (props:NavigationParams) => {
    if(props.account.infos.user_group === "direction") {
        return navigation({server : props.server})
    }
    const access_id = props.account.infos.access.map((acc:any) => acc.id_menu)
    return navigation({server : props.server}).filter(nav => [1, ...access_id].includes(nav.id_menu) )
}