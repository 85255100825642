import React, {useEffect} from 'react'
import {motion} from 'framer-motion'
import { StatusRequestType } from '../../../../store/types.store'



interface StatusRequestInterface {
    response : StatusRequestType, 
    closeStatusRequest : () => void, 
    duration? : number
}

const StatusRequest:React.FC<StatusRequestInterface> = (props) => {
    const duration = props.duration?? 3000
    useEffect(() => {
        const timer = setTimeout(() => {
            props.closeStatusRequest()
        }, duration);
        return () => clearTimeout(timer);
    });

    return (
        <motion.div 
            key = {JSON.stringify(props.response)}
            exit = {{y : -300, opacity : 0}}
            
            initial = {{y : -300, opacity : 0}}
            animate = {{y : 0, opacity : 1}}
            transition = {{duration : 0.3}}
            //variants={statusRequest__animation}
            onClick={() => props.closeStatusRequest()}
            className={`statusRequest statusRequest__${props.response.status ? "success" : props.response.status === null ?   "info" : "error"}`}
        >
            <div></div>
            <p>{props.response.message}</p>
        </motion.div>
    )
}

export default StatusRequest ; 