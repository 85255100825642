import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ServerProps, server_host } from "../../config/server.config"
import { v4 } from "uuid"
import { account_user } from "../../store/account/selector.account"
import { addRequestStatus, setLoaderStatus } from "../../store/global/actions.global"
import { motion, AnimatePresence } from "framer-motion"
import PreviewFileDeposit from "./layouts/PreviewFileDeposit"
import BaseApp from "../../tools/components/general/BaseApp"
import BaseContainer from "../../tools/components/general/BaseContainer"
import DownloadFileDeposit from "./layouts/DownloadFileDeposit"
import SearchSelect from "../../tools/components/general/SearchSelect"
import ImportModule, { ImportProps } from "../../tools/components/general/ImportModule"
import { Icons, StringUtils } from "tc-minibox"
import {ReactComponent as UploadSVG} from '../../assets/svg/illustrations/upload.svg'
import MainButton from "../../tools/components/general/MainButton"
import DropDown from "../../tools/components/general/DropDown"
import { models } from "./generate-sales.utils"

export interface FileDepositProps extends ServerProps {

}

interface FileDepositForm {
  token_zip: string;
  id_user: number | null;
  model_name: string;
  files: File[];
}

interface FileDepositManager {
    preview: {
        status : boolean,
        url : string | null
    },
    download : {
        status : boolean,
        url : string | null
    }
}

interface EventsProps {
    hover : string | null,
}

// Votre état initial pour "preview"
const initialize_preview = {
  status: false,
  url: null,
};

// La fonction pour initialiser le formulaire
const initialize_form = (id_user: number | null): FileDepositForm => {
  return {
    token_zip: v4(),
    id_user: id_user,
    model_name: models[0].name,
    files: [],
  };
};


export default function GenerateSales(props:FileDepositProps) {
    // * GLOBAL VARIABLES
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)
    const mimetypes_authorized = [
        'application/pdf',
        'image/jpeg',
        'image/png',
    ];

    // * STATES
    const [manager, setManager] = useState<FileDepositManager>({
        preview : initialize_preview, 
        download : {
            status : false, 
            url : null
        }
    })
    const [form, setForm] = useState<FileDepositForm>(initialize_form(user_account.infos.id_user))
    const [events, setEvents] = useState<EventsProps>({
        hover : null
    })


    // * FUNCTIONS
    const handlePreview = (file:File) => {
        const imageUrl = URL.createObjectURL(file);
        setManager(state => {return({...state, preview : {...state.preview, status : true, url : imageUrl}})})
    }

    const handleSubmit = () => {
        if(form.files.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez importer au moins un fichier"}))
        }

        const data = new FormData();
        data.append('token_zip', form.token_zip);
        data.append('id_user', `${form.id_user}`);
        data.append('model_name', form.model_name);

        // Ajoutez chaque fichier individuellement au FormData
        for (let i = 0; i < form.files.length; i++) {
            data.append('files', form.files[i]);
        }
        dispatch(setLoaderStatus({status : true, message : "Téléchargement des fichiers..."}))
        props.server.post('/generate-sales', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            setManager(state => {return({...state, download : {...state.download, status : true, url : res.data.data}})})
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }


    const handleDownload = () => { 
        const link = document.createElement('a');
        
        link.href = server_host(manager.download.url !== null ? manager.download.url : '');
        link.download = 'Generate-sales.zip'; // Nom du fichier à télécharger
        link.target = '_blank'; // O

        // Ajouter le lien au DOM et le déclencher
        document.body.appendChild(link);
        link.click();

        // Supprimer le lien du DOM une fois le téléchargement terminé
        document.body.removeChild(link);

        dispatch(setLoaderStatus({status : false}))
    }

    // * CONFIG COMPONENTS
    const import_config:ImportProps = {
        mode : "reduced", 
        multiple : true,
        subtitle : ".pdf, .jpeg, .jpg, .png", 
        handleSubmit : (files:File[]) => setForm(state => {return({...state, files : [...state.files, ...files]});})
    }
    
    return (
        <React.Fragment>
            <AnimatePresence>
            {
                manager.preview.status ?
                    <PreviewFileDeposit
                        url = {manager.preview.url} 
                        handleClose = {() => setManager(state => {return({...state, preview : initialize_preview})})}
                    />
                : 
                    null
            }
            </AnimatePresence>
        <BaseApp>
            <BaseContainer nameClass = {`file-deposit generate-sales`}>
                {
                    manager.download.status ?
                        <DownloadFileDeposit
                            handleDownload = {() => handleDownload()}
                            handleNewDeposit = {() => {
                                setManager(state => {return({...state, download : {...state.download, status : false}})})
                                setForm(initialize_form(user_account.infos.id_user))
                            }}
                        />
                    :
                        <React.Fragment>

                        <motion.div 
                            className='model_name'
                        >
                            <label htmlFor="model_name">Choisissez un modèle</label>
                            <DropDown 
                                {
                                    ...{
                                        reference : "model_name",
                                        hover : events.hover, 
                                        setHover : (value) => setEvents(state => {return({...state, hover : value})}), 
                                        value : form.model_name, 
                                        setValue : (value) => setForm(state => {return({...state, model_name : value.name})}),
                                        list : models.map(el => {
                                            return {
                                                id : el.name, 
                                                name : el.name, 
                                                text : el.text
                                            }
                                        })
                                    }
                                }
                            />
                        </motion.div>
                        <motion.div 
                            className='file-deposit--files'
                        >
                            <label htmlFor="files">Fichiers à importer</label>
                            <ImportModule
                                {...import_config}
                            />
                            {
                                form.files.length > 0 ?
                                    <div className='file-deposit--files'>
                                        <AnimatePresence>
                                        {
                                            form.files.map((file, index) => (
                                                <motion.div 
                                                    key = {`FILE-IMPORT-0${index}`}
                                                    className='file'
                                                >
                                                    {mimetypes_authorized.includes(file.type) ? 
                                                        <div 
                                                            onClick={() => handlePreview(file)}
                                                            className='preview-icon'
                                                        >
                                                            <Icons name = "eye" mode = "duotone" color = "white" />
                                                        </div>
                                                    : 
                                                        null
                                                    }

                                                    <p>{file.name}</p>
                                                    <p className={`mimetype mimetype--${mimetypes_authorized.includes(file.type) ? "valid" : "invalid"}`}>{mimetypes_authorized.includes(file.type) ? "Fichier valide" : "Fichier invalide"}</p>
                                                    <div 
                                                        className='delete'
                                                        onClick = {() => setForm(state => {return({...state, files : state.files.filter(f => f.name !== file.name)})})}
                                                    >
                                                        <Icons name = "xmark" mode = "duotone" color = "white" />
                                                    </div>
                                                </motion.div>
                                            ))
                                        }
                                        </AnimatePresence>
                                    </div>
                                : 
                                    <div className = 'file-deposit--files--empty'>
                                        <UploadSVG />
                                    </div>
                            }

                        </motion.div>
                        <MainButton
                            handleClick = {() => handleSubmit()}
                        >
                            Importer
                        </MainButton>
                        </React.Fragment>

                }

            </BaseContainer>
        </BaseApp>
        </React.Fragment>
    )
}