import {motion} from 'framer-motion';

export interface PreviewFileDepositProps {
    url : string|null,
    handleClose : () => void
}

export default function PreviewFileDeposit(props:PreviewFileDepositProps) {
    return (
        <motion.div 
            exit={{opacity : 0}}
            initial={{opacity : 0}}
            animate={{opacity : 1}}
            className='file-deposit__preview'>
            <div className='file-deposit__preview--background' onClick = {props.handleClose}></div>
            <motion.div 
                exit={{opacity : 0, y : 100}}
                initial={{opacity : 0, y : 100}}
                animate={{opacity : 1, y : 0, transition : {delay : 0.2, duration : 0.3}}}
                className='file-deposit__preview--container'>
                <iframe 
                    src={`${props.url}`} 
                    title="Web Page Viewer" 
                    className="iframe-container"
                    onError={() => {console.log('error')}}
                />
            </motion.div>
        </motion.div>
    )
}