import { useEffect, useState } from "react";
import { icons_config } from "../../config/context.config";
import { ServerProps } from "../../config/server.config";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { Table } from "tc-minibox";
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../store/global/actions.global";
import DetailDoc, { DetailDocProps } from "./layouts/detail/DetailDoc";



export interface DocumentsProps extends ServerProps {

}

export interface DocumentsManager {
    initialize: boolean,
    selected_data: null | number,
}

export default function Documents(props:DocumentsProps) {
    // * GLOBAL VARIABLES
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // * STATES
    const [clients, setClients] = useState<any[]>([])
    const [manager, setManager] = useState<DocumentsManager>({ 
        initialize : false,
        selected_data : null,
    })

    // * VARIABLES
    const tbody = clients.map((cl:any) => {
        return {
            ...cl, 
            nb_total : cl.nb_valid + cl.nb_invalid,
        }
    })


    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true}))
            props.server.post('/documents/initialize')
            .then(res => {
                setManager((state:any) => { return {...state, initialize : true}})
                setClients(res.data.data)
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    // * CONFIG COMPONENTS
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : icons_config, 
        config : [
            // *
            {
                info : {
                    text : "Code",
                    name : "code", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Nom", 
                    name : "name", 
                }, 
                style : {
                    width : "calc(100% - 640px)", 
                }
            }, 
            {
                info : {
                    text : "Valides", 
                    name : "nb_valid", 
                }, 
                style : {
                    width : `120px`, 
                    tbody : {
                        width : "calc(100% - 20px)",
                        justifyContent : "center",
                        textAlign : "center", 
                        padding : "0px 10px", 
                    }
                }
            }, 
            {
                info : {
                    text : "Invalides",
                    name : "nb_invalid", 
                }, 
                style : {
                    width : "120px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 

            {
                info : {
                    text : "Total fichiers", 
                    name : "nb_total", 
                }, 
                style : {
                    width : "150px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            
            {
                info : {
                    text : "Dernier dépôt", 
                    name : "last_insert", 
                    type : "date"
                }, 
                style : {
                    width : "150px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setClients,
        header : {
            buttons : {
                include : ["add"], 
                actions : {
                    add : () => navigate('/deposit'),
                }
            }
        }, 
        handleDoubleClick : (tr:any) => {setManager(state => {return{...state, mode : "detail", selected_data : tr.id_client}})}
    }

    const detail_config:DetailDocProps = {
        server : props.server,
        data : clients,
        selected_data : manager.selected_data,
        handleReturn : () => setManager(state => {return{...state, selected_data : null}})
    }

    return (
        <BaseApp>

                {
                    manager.selected_data === null ? 
                        <BaseContainer nameClass="documents">
                            <Table {...table_config} />
                        </BaseContainer>
                    : 
                        <DetailDoc {...detail_config} />
                }


        </BaseApp>
    )
}