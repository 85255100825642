//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

// * SOCKET CONFIG ///////////``
//export const socket_server = io('http://localhost:4000', { transports : ['polling']});
export const socket_server = io('https://ineed.re', { transports : ['polling']});

// * NODEJS SERVER ///////////
export const server_host = (route:string="") => { return `https://ineed.re/api/${route}` }
//export const server_host = (route:string="") => { return `http://localhost:4000/api/${route}`}
