import React, {useEffect, useState} from 'react' ; 
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import {Table} from 'tc-minibox'
import { ServerProps } from "../../config/server.config";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { icons_config } from "../../config/context.config";
import { useDispatch } from 'react-redux';
import { addRequestStatus, setLoaderStatus, set_popup_warning } from '../../store/global/actions.global';
import ClientsManager, { ClientsManagerProps } from './layouts/manager/ClientsManager';
import { AnimatePresence } from 'framer-motion';



export interface ClientsProps extends ServerProps {

}

export interface ClientsManager {
    initialize : boolean, 
    mode : "list" | "detail", 
    add : boolean, 
    update : {
        status : boolean, 
        content : any
    }

}

export default function Clients(props:ClientsProps) {
    //* GLOBAL VARIABLES 
    const dispatch = useDispatch()

    // * STATES
    const [clients, setClients] = React.useState<any[]>([])
    const [manager, setManager] = React.useState<ClientsManager>({
        initialize : false,
        mode : "list",
        add : false, 
        update : {
            status : false,
            content : {}
        }
    })

    // * VARIABLES 
    const tbody = clients

    // * FUNCTIONS 
    const handleDelete = (selected_data:any) => {
        const to_delete = selected_data.map((d:any) => d.id_client)
        dispatch(setLoaderStatus({status : true}))

        props.server.post('/clients/delete', {to_delete : to_delete})
        .then(res => {
            setClients(clients.filter(c => !to_delete.includes(c.id_client)))
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true}))
            props.server.post('/clients/initialize')
            .then(res => {
                setManager((state:any) => { return {...state, initialize : true}})
                setClients(res.data.data)
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [])

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : icons_config, 
        config : [
            // *
            {
                info : {
                    text : "Code",
                    name : "code", 
                }, 
    
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Nom", 
                    name : "name", 
                }, 
                style : {
                    width : "calc(100% - 650px)", 
                }
            }, 
            {
                info : {
                    text : "Activité", 
                    name : "activity", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        justifyContent : "center",
                        textAlign : "center", 
                    }
                }
            }, 
            {
                info : {
                    text : "Clôture", 
                    name : "closing", 
                }, 
                style : {
                    width : `100px`, 
                    tbody : {
                        width : "calc(100% - 20px)",
                        justifyContent : "center",
                        textAlign : "center", 
                        padding : "0px 10px", 
                    }
                }
            }, 
            {
                info : {
                    text : "Statut",
                    name : "tax_status", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 

            {
                info : {
                    text : "Régime", 
                    name : "tax_system", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            
            {
                info : {
                    text : "TVA", 
                    name : "tva_system", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setClients,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add : () => setManager(state => {return{...state, add : true}}),
                    delete : (data:any) => {
                        console.log()
                        if(data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins une client."}))
                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ces affaires ?", 
                            handleYes : () => handleDelete(data)
                        }))
                    }
                }
            }
        }, 
        handleDoubleClick : (tr:any) => {
            const selected_data = clients.find((cl) => cl.id_client === tr.id_client)
            setManager(state => {return{...state, update : {status : true, content : selected_data}, add : true}})

        }
    }

    const clients_manager:ClientsManagerProps = {
        ...manager.update.status ? {update : manager.update} : {}, 
        server : props.server,
        handleClose : () => setManager(state => {return{...state, add : false, update : {status : false, content : {}}}}),
        setData : setClients, 
        data : clients
    }


    return (
        <React.Fragment>
            <AnimatePresence>
            {
                manager.add ? 
                    <ClientsManager {...clients_manager}/>
                : null
            }
            </AnimatePresence>
            <BaseApp>
                <BaseContainer nameClass="clients">
                    <Table {...table_config} />
                </BaseContainer>        
            </BaseApp>
        </React.Fragment>
    )
}