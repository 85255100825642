import {motion} from 'framer-motion' ; 

export default function Separator() {
    return (
        <motion.div 
            className="separator"
            {...{
                exit : {
                    height : 0, 
                    opacity : 0
                }, 
                initial : {
                    height : 0, 
                    opacity : 0
                }, 
                animate : {
                    height : "100%", 
                    opacity : 1, 
                    transition : {
                        delay : 0.3
                    }
                }, 
            }}
        >
            
        </motion.div>
    )
}