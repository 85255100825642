import {motion} from 'framer-motion' ; 

export interface MainButtonInterface {
    handleClick : () => void, 
    children: React.ReactNode;
}

export default function MainButton(props:MainButtonInterface) {

    return (
        <motion.div 
            whileHover={{ scale : 1.05}}
            whileTap={{ scale : 0.9}}
            className="main-button"
            onClick = {props.handleClick}
        >
            {props.children}
        </motion.div>
    )
}