import React from 'react' ; 
import {useRef, useCallback} from 'react';
import {motion} from 'framer-motion' ; 
import {useDropzone} from 'react-dropzone';
import {ReactComponent as AddIllustration} from '../../../assets/svg/illustrations/download-pana.svg' ; 
import {ReactComponent as DragOn} from '../../../assets/svg/icons/drag_on.svg' ; 
import { Icons } from 'tc-minibox';

export interface ImportProps {
    mode? : "normal"|"reduced",
    multiple? : boolean, 
    subtitle : string,
    nameClass? : string,
    handleSubmit : (files:any) => void
}

const dragNdrop_animation = {
    initial : {y : 100, opacity : 0}, 
    animate : {y : 0, opacity : 1, transition : {type : "linear", delay : 0.6}}, 
    exit : {y : 100, opacity : 0}
}

export default function ImportModule(props:ImportProps) {
    /*
        Neccesary props : 
            subtitle = {}
            file = {}
            handleSubmit = {}
    */
    const mode = props.mode?? "normal"
    const fileInputRef = useRef(null)
    const onDrop = useCallback((acceptedFiles:File[]) => {
        props.handleSubmit(props.multiple ? acceptedFiles : acceptedFiles[0])
    }, [props])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: false})

    return (
        <div 
            {...getRootProps()}
            className={`${props.nameClass ? props.nameClass : ""} ${mode === "reduced" ? "import-reduced" : "import"}`}
        > 
            <div>
                {
                    mode === "reduced" ?
                        <Icons name = "upload" mode = "duotone" color = "#29004963" />
                    : null
                }
                    {
                        mode === "normal" ?
                            <div className='import__intro' >
                            <h1>Importer un fichier</h1>
                            <p>{props.subtitle}</p>
                            </div>
                        : null
                    }

                            <div 
                                className='import__illustration'
                                
                            >
                                {
                                    mode === "normal" ?
                                        <AddIllustration />
                                    : null
                                }
                                <p>
                                    Glissez et déposez vos fichiers ici ou choisissez des fichiers.
                                </p>
                                {
                                    mode === "reduced" ?
                                        <p>{`Format(s) autorisé(s) : ${props.subtitle}`}</p>
                                    : null
                                }
                </div>
            </div>

        {
            isDragActive ?
                <div className='import__dragMode'>
                    <DragOn />    
                </div>
            : null
        }

        <input 
            type = "file"
            ref={fileInputRef} 
            {...getInputProps()}
        />




        </div>


    )
}