import React, { useEffect, useState} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Icons, StringUtils } from 'tc-minibox';
import { Axios } from 'axios';
import MiniLoad from './MiniLoad';

type ListItem = {
    text: string;
    id: string | number, 
    [key:string] : any
};


interface SearchSelectProps {
    adding?: boolean;
    handleAdd?: (e:string) => void;
    no_data_text?: string;
    handleClick : (e:ListItem) => void;
    handleDelete : () => void;
    value : any; 
    server : {
        route: string;
        axios : Axios
    }, 
}

export const submenu_variants = {
    exit : {height : 0, opacity : 0}, 
    animate : {height : 'auto', opacity : 1}, 
    initial : {height : 0, opacity : 0}
}

export default function SearchSelect(props:SearchSelectProps) {
    const [search, setSearch] = useState({
        open: false,
        status: "resolve",
        input: "",
        list: [] as ListItem[]
    });

    const [manager, setManager] = useState({
        initialize : false
    })

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(state => ({
            ...state,
            open: e.target.value.length > 0,
            status: "pending",
            input: e.target.value,
        }));
    }

    const handleUnfocus = () => {
        let input = "";
        if (!StringUtils.isUndefinedValue(props.value)) {
            input = search.list.find(e => e.id === props.value)?.text || "";
        }
        setSearch(state => ({
            ...state,
            open: false,
            input : input
        }));
    }

    const handleBlur = () => {
        if (search.input.length === 0 && !StringUtils.isUndefinedValue(props.value)) {

        }
    }

    const handleClick = (e:ListItem) => {
        setSearch(state => ({
            ...state,
            status: "resolve",
            open: false,
            input: e.text
        }));
        props.handleClick(e);
    }


    useEffect(() => {
        if(search.input.length === 0 && !StringUtils.isUndefinedValue(props.value)) {
            props.handleDelete()
        }
        const timer = setTimeout(() => {
            if (search.status === "pending") {
                props.server.axios.post(props.server.route, { search_string: search.input })
                    .then((res: any) => {
                        setSearch(state => ({
                            ...state,
                            status: "resolve",
                            list: res.data.data
                        }));
                    })
                    .catch(() => {
                        setSearch(state => ({
                            ...state,
                            status: "resolve",
                            list: []
                        }));
                    });
            }
        }, 800);

        return () => {
            clearTimeout(timer);
        };
    }, [search.input]);

    useEffect(() => {
        if(!StringUtils.isUndefinedValue(props.value) && search.input.length === 0) {
            props.server.axios.post(props.server.route, { search_string: search.input })
            .then((res: any) => {
                setSearch(state => ({
                    ...state,
                    input : res.data.data.find((e:any) => e.id === props.value)?.text || "",
                }));
            })
            .catch(() => {

            });
        }
    },[search.input, props.value])



    useEffect(() => {
        if(!StringUtils.isUndefinedValue(props.value) && !manager.initialize) {
            props.server.axios.post(props.server.route, { search_string: " "})
            .then((res: any) => {
                setManager(state => ({...state, initialize : true}))
                setSearch(state => ({
                    ...state,
                    status: "resolve",
                    input : res.data.data.find((e:any) => e.id === props.value)?.text || "",
                }));
            })
            .catch(() => {
                setSearch(state => ({
                    ...state,
                    status: "resolve",
                    list: []
                }));
            });
        }
    }, [])


    return (
        <div id='search-select'>
            <Icons name={'magnifying-glass'} mode="duotone" color="grey" />
            <input
                type="text"
                name="search"
                value={search.input}
                placeholder='Tapez ici'
                autoComplete="off"
                onBlur={handleBlur}
                onChange={handleChangeSearch}
            />
            {search.open && <div className='list--background' onClick={handleUnfocus}></div>}
            <AnimatePresence>
                {search.open &&
                    <motion.div {...submenu_variants} className='list'>
                        {props.adding && !search.list.find(ls => StringUtils.undress_string(ls.name) === StringUtils.undress_string(search.input)) ?
                            <div className='add' onClick={() => props.handleAdd && props.handleAdd(search.input)}>
                                <Icons name="plus" />
                                <p>{search.input}...</p>
                            </div>
                            : null}
                        {search.status === "resolve" ?
                            <React.Fragment>
                                {search.list.length > 0 ?
                                    search.list.map(sl => (
                                        <div className='list--element' onClick={() => handleClick(sl)} key={`SEARCH-SEL-LIST-ELMT${sl.id}`}>
                                            <p>{sl.text}</p>
                                        </div>
                                    ))
                                    : <div className='list--nodata'>{props.no_data_text}</div>}
                            </React.Fragment>
                            : <MiniLoad />}
                    </motion.div>}
            </AnimatePresence>
        </div>
    );
}
