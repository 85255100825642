import { ModalsProps } from "./utils.clientsmanager";



export default function Identification(props:ModalsProps) {
    return (
        <div className="clients-manager__identification">
            <div>
                <label htmlFor="code">Code</label>
                <input 
                    type="text" 
                    name="code" 
                    id="code" 
                    value={props.form.code} 
                    onChange={(e) => props.handleChangeForm(e, /[^a-zA-Z0-9]/g)}
                />
            </div>
            <div>
                <label htmlFor="name">Nom</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={props.form.name}
                    onChange={(e) => props.handleChangeForm(e)}
                />
            </div>
        </div>
    )
}
