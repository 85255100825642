import React from 'react' ; 
import { useEffect, useState } from "react";
import { ServerProps, server_host } from "../../../../config/server.config";
import BasePopup from "../../../../tools/components/general/BasePopup";
import ContainerLoad from "../../../../tools/components/general/ContainerLoad";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../../../store/global/actions.global";
import { Table } from "tc-minibox";
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import { icons_config } from "../../../../config/context.config";
import PreviewFileDeposit from '../../../file_deposit/layouts/PreviewFileDeposit';
import { account_user } from '../../../../store/account/selector.account';
import { AnimatePresence } from 'framer-motion';


export interface SelectedPrefixProps extends ServerProps {
    handleClose : () => void,
    data : any,
    selected_data : null | number | "all" | "invalid",
    client : any,
}

export interface SelectedPrefixManager {
    initialize : boolean,
    mode : "normal" | "load", 
    selected_data : null | string
}

export default function SelectedPrefix(props:SelectedPrefixProps) {
    // * GLOBAL VARIABLES
    const dispatch = useDispatch()
    const user = useSelector(account_user)
    const selected_data = props.data.find((cl:any) => cl.prefix === props.selected_data)


    // * STATES
    const [manager, setManager] = useState<SelectedPrefixManager>({
        initialize : false,
        mode : "load", 
        selected_data : null
    })
    const [files, setFiles] = useState<any[]>([])

    // * VARIABLES
    const tbody = files.map((fl:any) => {
        return {
            ...fl, 
            intervenant : `${fl.user.firstname} ${fl.user.lastname}`
        }
    })


    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            const selected_client = props.data.find((cl:any) => cl.id_client === props.selected_data)
            props.server.post('/documents/prefix-files', {id_client : props.client.id_client, prefix : selected_data.prefix})
            .then(res => {
                setManager((state:any) => { return {...state, initialize : true, mode : "normal"}})
                setFiles(res.data.data)
            })
            .catch(err => {
                setManager((state:any) => { return {...state, mode : "normal"}})
                dispatch(addRequestStatus(err.response.data))
            })
        }
    }, [manager.initialize])

    // * FUNCTIONS
    const handleDownload = (data:any, watermark:boolean) => {
        const data_id = data.map((dt:any) => dt.id_file)
        dispatch(setLoaderStatus({status : true}))
        props.server.post('/documents/download-files', {id_file : data_id, addWatermark : watermark})
        .then(res => {
            dispatch(setLoaderStatus({status : false}))

            const link = document.createElement('a');
        
            link.href = server_host(res.data.data !== null ? res.data.data : '');
            link.download = 'Séquentialité.zip'; // Nom du fichier à télécharger
            link.target = '_blank'; // O
    
            // Ajouter le lien au DOM et le déclencher
            document.body.appendChild(link);
            link.click();
    
            // Supprimer le lien du DOM une fois le téléchargement terminé
            document.body.removeChild(link);

            props.server.post('/documents/remove-zip', {url : res.data.data})
            .then(_ => {})
            .catch(_ => {})
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
    }

    // * CONFIG COMPONENTS
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : icons_config, 
        config : [
            // *
            {
                info : {
                    text : "Préfixe",
                    name : "prefix", 
                }, 
                style : {
                    width : "200px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "N° séquentiel", 
                    name : "seq_number", 
                }, 
                style : {
                    width : "250px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Ajouté par", 
                    name : "intervenant", 
                }, 
                style : {
                    width : `calc(100% )`, 
                    tbody : {
                        width : "calc(100% - 750px)",
                        justifyContent : "center",
                        textAlign : "center", 
                        padding : "0px 10px", 
                    }
                }
            }, 
            {
                info : {
                    text : "Date d'ajout",
                    name : "date_insert", 
                    type : "date"
                }, 
                style : {
                    width : "300px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setFiles,
        header : {
            buttons : {
                include : [], 
                actions : {
                    //add : () => navigate('/deposit'),
                }
            },
            custom : (selected_data:any) => {
                const selection = files.filter((_, index) => selected_data.includes(index))

                return (
                    <div className = "selected-prefix--header">
                        {
                            selection.length > 0 ?
                                <button
                                    onClick = {() => {
                                        return dispatch(set_popup_warning({
                                            status : true, 
                                            text : "Souhaiteriez-vous y appliquer un filigrane ?", 
                                            handleYes : () => handleDownload(selection, true), 
                                            handleNo : () => handleDownload(selection, false)
                                        }))
                                    }}
                                >
                                    Télécharger
                                </button>

                            : 
                                null
                        }

                    </div>
                )
            }
        }, 
        handleDoubleClick : (tr:any) => {setManager((state:any) => {return{...state, selected_data : server_host(`${tr.path}`)}})}
    }

    return (
        <React.Fragment>
            <AnimatePresence>
            {
                manager.selected_data !== null ? 
                    <PreviewFileDeposit 
                        url = {manager.selected_data}
                        handleClose = {() => setManager(state => {return{...state, selected_data : null}})}
                    />

                : null
            }
            </AnimatePresence>
            <BasePopup handleClose = {props.handleClose} nameClass="selected-prefix">
                <ContainerLoad load = {manager.mode === "load"}>
                    <Table {...table_config} />
                </ContainerLoad>
            </BasePopup>
        </React.Fragment>
    )
}