
import moment from "moment"
import { DetailDocManager } from "../DetailDoc"
export interface PrefixesProps {
    data : any[],  
    setManager : React.Dispatch<React.SetStateAction<DetailDocManager>>,
}

export default function Prefixes(props:PrefixesProps) {

    return (
        <div className = "detail-doc--prefixes">
            <div className = "header">
                <button
                    onClick= {() => props.setManager((state:any) => { return {...state, selected_data : "invalid"}})}
                >
                   Consulter les fichiers invalides
                </button>
            </div>
            <div className = "container">
                <div>
                {
                    props.data.length > 0 ? 
                        props.data.filter(dt => dt.prefix !== null).map((dt:any) => (
                            <div 
                                key = {dt.prefix}
                                onClick = {() => props.setManager((state:any) => { return {...state, selected_data : dt.prefix}})}
                            >
                                <h1>{dt.prefix}</h1>
                                <div>
                                    <p>
                                        <span>Dernier numéro séquentiel :</span>
                                        <span>{dt.last_number}</span>
                                    </p>
                                    <p>
                                        <span>Dernier dépôt :</span>
                                        <span>{moment(dt.last_deposit).format('DD/MM/YYYY')}</span>
                                    </p>
                                    <p>
                                        <span>Dernier intervenant :</span>
                                        <span>{`${dt.last_intervention.firstname} ${dt.last_intervention.lastname}`}</span>
                                    </p>
                                </div>
                            </div>
                        ))
                    : 
                        null
                }
                </div>

            </div>
                
        </div>
    )

}