import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Identification from "./grid/Identification"
import { ModalsProps } from "./grid/utils.clientsmanager";
import Taxes from "./grid/Taxes";
import { ServerProps } from "../../../../config/server.config";
import OtherInfo from "./grid/OtherInfo";
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global";
import BasePopup from "../../../../tools/components/general/BasePopup";
import CategorizerManager, { CategorizerManagerProps } from "../../../../tools/components/general/Categorizer";
import MainButton from "../../../../tools/components/general/MainButton";


export interface ClientsManagerProps extends ServerProps {
    update? : any, 
    handleClose : () => void,
    data : any,
    setData : (state:any) => void,
}

export interface ClientForm {
    id_client?: number;
    code: string;
    name: string;
    tax_status: string;
    tax_system: string;
    tva_system: string;
    activity: string;
    closing: string;
}

export default function ClientsManager(props:ClientsManagerProps) {
    const dispatch = useDispatch()
    const [form, setForm] = useState<ClientForm>({
        code : "", 
        name : "",
        tax_status : "", 
        tax_system : "", 
        tva_system : "", 
        activity : "", 
        closing : ""
    })


    //function to chnage form with an optional regex replace to ''
    const handleChangeForm = (e:React.ChangeEvent<HTMLInputElement>, regex = null) => {
        if(regex) {
            setForm({...form, [e.target.name] : e.target.value.replace(regex, '')})
        } else {
            setForm({...form, [e.target.name] : e.target.value})
        }
    }

    const modals_config:ModalsProps = {
        form : form, 
        setForm : setForm,
        handleChangeForm : handleChangeForm
    }



    const handleSubmit = () => {
        if(form.code.trim() === "") {
            return dispatch(addRequestStatus({
                status : false,
                message : "Le code est obligatoire"
            }))
        }

        if(form.name.trim() === "") {
            return dispatch(addRequestStatus({
                status : false,
                message : "Le nom est obligatoire"
            }))
        }


        dispatch(setLoaderStatus({status : true}))

        if(props.update?.status) {
            return props.server.post('/clients/update', form) 
            .then(res => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus(res.data))
                props.setData((state:any) => state.map((st:any) => {
                    if(st.id_client === form.id_client) {
                        return form
                    } else {
                        return st
                    }
                }))
                props.handleClose()
                
    
    
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }


        return props.server.post('/clients/add', form) 
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(res.data))
            props.setData((state:any )=> [res.data.data, ...state])
            props.handleClose()
            


        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    useEffect(() => {
        if(props.update?.status) {
            setForm(props.update.content)
        }
    }, [])


    const config_categorizer:CategorizerManagerProps = {
        config : [
            {
                id: "CLIENTS-MANAGER-01", //
                name: "Identification", //
                svg: "user-cog", // 
                component: <Identification {...modals_config}/>
            }, 
            {
                id: "CLIENTS-MANAGER-02", //
                name: "Taxes", //
                svg: "money-bill", // 
                component: <Taxes form = {form} setForm = {setForm} handleChangeForm = {handleChangeForm}/>
            }, 
            {
                id: "CLIENTS-MANAGER-03", //
                name: "Autres infos", //
                svg: "seal-question", // 
                component: <OtherInfo form = {form} setForm = {setForm} handleChangeForm = {handleChangeForm}/>
            }, 
        ]

    }



    return (
        <BasePopup
            nameClass = "clients-manager"
            handleClose = {props.handleClose}
        >
            <CategorizerManager {...config_categorizer} />

            {
                props.update?.status ?
                    JSON.stringify(form) !== JSON.stringify(props.data.find((cl:any) => cl.id_client === props.update.content.id_client)) ?
                    <MainButton
                        handleClick = {() => handleSubmit()}
                    >
                        Modifier
                    </MainButton>
                    : 
                        null
                : 
                    <MainButton
                        handleClick = {() => handleSubmit()}
                    >
                        Ajouter
                    </MainButton>

            }

            

        </BasePopup>
    )
}