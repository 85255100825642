import { ModalsProps } from "./utils.clientsmanager";




export default function OtherInfo(props:ModalsProps) {
    return (
        <div className='clients-manager__other-info'>
            <div>
                <label htmlFor="activity">Activité</label>
                <input
                    type="text"
                    name="activity"
                    id="activity"
                    value={props.form.activity}
                    onChange={(e) => props.handleChangeForm(e)}
                />
            </div>
            <div>
                <label htmlFor="closing">Clôture</label>
                <input
                    type="text"
                    name="closing"
                    id="closing"
                    value={props.form.closing}
                    onChange={(e) => props.handleChangeForm(e, /\d{2}\/\d{2}/g)}
                />
            </div>
        </div>  
        
    )
}