import React from 'react' ; 
import {motion} from 'framer-motion' ; 
export interface BaseContainerProps {
    key_id? : number | string
    nameClass ?: string, 
    children? : React.ReactNode
}

const animationVariants = { 
    initial : {y : 120, opacity : 0}, 
    animate : {y : 0, opacity : 1}, 
    exit : {y : 120, opacity : 0}, 
    transition : {
        type : "linear", 
        duration : 0.2
    }
}

export default function BaseContainer(props:BaseContainerProps) {
    return (
        <motion.div 
            key = {props.key_id?? "BASE-CONTAINER"}
            {...animationVariants}
            className={`app__container ${props.nameClass}`}
        >
            {props.children as JSX.Element}
        </motion.div>
    )
}