import {ReactComponent as Logo} from '../assets/svg/logo.svg'
import logo_min from '../assets/images/logo_minified.png'

// * COMPAGNY
export const compagny = {
    logo : <Logo />, 
    logo_min : logo_min
}

// * ICONS
export const icons_config = {
    mode : "duotone", 
    color : "#290049" 
}