import MainButton from "../../../tools/components/general/MainButton";
import { motion } from "framer-motion";
import {ReactComponent as DownloadSVG} from '../../../assets/svg/illustrations/download__illustration.svg'

export interface DownloadFileDepositProps {
    handleDownload : () => void,
    handleNewDeposit : () => void
}

export default function DownloadFileDeposit(props: DownloadFileDepositProps) {
    return (
        <div className='file-deposit__download'>
            <motion.div 
                className='file-deposit__download--container'
                onClick = {props.handleDownload}    
            >
                <DownloadSVG />
                <p>Clique ici pour télécharger</p>
            </motion.div>

            <MainButton
                handleClick = {props.handleNewDeposit}
            >
                Nouveau dépôt
            </MainButton>
        </div>

    )
}
