import { AuthType } from "../types.store";
import { LOGIN, LOGOUT } from "./reducer.account";


export const set_authentification = (data:{[key : string] : any}) => ({
    type : LOGIN,
    payload : data
})


export const logout = () => ({type : LOGOUT})