import React from 'react'
import { useEffect, useState } from "react";
import { ServerProps } from "../../../../config/server.config";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global";
import Prefixes, { PrefixesProps } from "./grid/Prefixes";
import GeneralInfos, { GeneralInfosProps } from "./grid/GeneralInfos";
import BaseContainer from "../../../../tools/components/general/BaseContainer";
import SelectedPrefix, { SelectedPrefixProps } from '../selected_prefix/SelectedPrefix';
import { AnimatePresence } from 'framer-motion';
import { Icons } from 'tc-minibox';
import { icons_config } from '../../../../config/context.config';
import InvalidDoc from '../invalid_doc/InvalidDoc';


export interface DetailDocProps extends ServerProps {
    data : any, 
    selected_data : number | null,
    handleReturn : () => void,
}

export interface DetailDocManager {
    initialize : boolean,
    selected_data : null | number | "all" | "invalid",
}

export default function DetailDoc(props:DetailDocProps) {
    // * GLOBAL VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [prefixes, setPrefixes] = useState<any[]>([])
    const [generalities, setGeneralities] = useState<any>({
        nb_valid : 0,
        nb_invalid : 0,
    })
    const [manager, setManager] = useState<DetailDocManager>({
        initialize : false,
        selected_data : null,
    })

    // * VARIABLES

    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true}))
            const selected_client = props.data.find((cl:any) => cl.id_client === props.selected_data)
            props.server.post('/documents/listing-prefixes', {id_client : selected_client.id_client})
            .then(res => {
                setManager((state:any) => { return {...state, initialize : true}})
                setPrefixes(res.data.data.prefixes)
                setGeneralities(res.data.data.generalities)
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    // * CONFIG COMPONENTS
    const prefixes_config:PrefixesProps = {
        data : prefixes,
        setManager : setManager
    }
    const generalInfos_config:GeneralInfosProps = {
        data : {
            ...generalities, 
            ...props.data.find((cl:any) => cl.id_client === props.selected_data)
        },
    }

    const selectedPrefix_config:SelectedPrefixProps = {
        server : props.server,
        client : props.data.find((cl:any) => cl.id_client === props.selected_data), 
        data : prefixes, 
        selected_data : manager.selected_data,
        handleClose : () => setManager((state:any) => { return {...state, selected_data : null}}), 
    }

    return (
        <React.Fragment>
            <AnimatePresence>
            {
                !["null", "invalid"].includes(`${manager.selected_data}`) ?
                    <SelectedPrefix {...selectedPrefix_config} />
                : 
                    null
            }
            {
                manager.selected_data === "invalid" ?
                    <InvalidDoc {...selectedPrefix_config} />
                : 
                    null
            }
            </AnimatePresence>
            <BaseContainer nameClass = "detail-doc">
                <div 
                    onClick = {props.handleReturn}
                    className='button-return'
                >
                    <Icons name = "arrow-turn" {...icons_config} />
                </div>
                <Prefixes {...prefixes_config} />
            </BaseContainer>

            <GeneralInfos {...generalInfos_config}/>
        </React.Fragment>
    )
}