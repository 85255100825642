import React from 'react' ; 
import { AnimatePresence } from 'framer-motion';
import StatusRequest from './layouts/StatusRequest' ; 
import Loader from './layouts/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { globalSelector } from '../../../store/global/selector.global';
import { clearRequestStatus, close_popup_warning } from '../../../store/global/actions.global';
import PopupWarning from '../general/PopupWarning';


export default function BasicTools() {
    const globalState = useSelector(globalSelector)
    const dispatch = useDispatch()

    /*
        STATUS REQUEST : 
            - props.statusRequest : contain the message of request's response
            - props.setStatusRequest : deactivate the Statut Request automatically
    */

    return (
        <React.Fragment>
            <AnimatePresence>
            { 
                Object.entries(globalState.statusRequest).length > 0 ? 
                    <StatusRequest response = {globalState.statusRequest} closeStatusRequest = {() => dispatch(clearRequestStatus())}/>
                : 
                    null 
            }
            </AnimatePresence>

            {/* /// LOADER /// */}
            <AnimatePresence>{ globalState.loader.status ? <Loader {...globalState.loader} /> : null}</AnimatePresence>

            {/* /// POPUP WARNING /// */}
            <AnimatePresence>{ globalState.warning.status ? <PopupWarning {...globalState.warning} handleClose = {() => dispatch(close_popup_warning())}/> : null}</AnimatePresence>
        </React.Fragment>
    )
}