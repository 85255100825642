
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


export interface GeneralInfosProps {
    data : any,  
}

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default function GeneralInfos(props:GeneralInfosProps) {

    
    const doughnut_config = {
        labels: [
          'Fichier(s) valide(s)',
          'Fichier(s) invalide(s)',
        ],
        datasets: [{
          data: [props.data.nb_valid, props.data.nb_invalid],
          backgroundColor: [
            '#2A9D8F',
            '#E63946',
          ],
          borderColor: [  // Contrôle la couleur de la bordure
            '#FFFFFF',    // Noir pour le segment valide
            '#FFFFFF',    // Noir pour le segment invalide
          ],
          borderWidth: [ 
            1, 
            1 
          ],
          hoverOffset: 4
        }]
    };
    
    const options = {
        responsive : true, 
        plugins: {
            legend: {
                display: false
            }
        }
    };
    
    return (
        <div className = "detail-doc--generalInfos">
            <h1>{`${props.data.code} - ${props.data.name}`}</h1>
            <div className = "doughnut">
                <Doughnut data={doughnut_config} options = {options}/>
            </div>
            <div className = "summary">
                <div className = "summary--element">
                    <div className = "color-total"></div>
                    <div>
                        <h2>{`Total fichiers`}</h2>
                        <p>{props.data.nb_valid + props.data.nb_invalid}</p>
                    </div>
                </div>
                <div className = "summary--element">
                    <div className = "color-valid"></div>
                    <div>
                        <h2>{`Fichiers valides`}</h2>
                        <p>{props.data.nb_valid}</p>
                    </div>
                </div>
                <div className = "summary--element">
                    <div className = "color-invalid"></div>
                    <div>
                        <h2>{`Fichiers invalides`}</h2>
                        <p>{props.data.nb_invalid}</p>
                    </div>
                </div>
            </div>
        </div>
    )
    
}