import {motion} from 'framer-motion';
import { loader_variants } from '../basictools.animations';
import { LoaderType } from '../../../../store/types.store';


export default function Loader(props:LoaderType) {

    return (
        <motion.div 
            exit = "exit"
            initial = "initial"
            animate = "animate"
            variants = {loader_variants.background}
            className='loader'
        >
            <div>
                <svg width="80" height="80" viewBox="0 0 80 80">
                    <polygon points="0 0 0 80 80 80 80 0" className="rect" />
                </svg>
                {props.message ? 
                    <p className = "loader--message">
                        {props.message}
                    </p>
                : null}
            </div>
        </motion.div>
    )
}