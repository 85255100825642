import { ModalsProps } from "./utils.clientsmanager";



export default function Taxes(props:ModalsProps) {
    return (
        <div className='clients-manager__taxes'>
            <div>
                <label htmlFor="tax_status">Statut fiscal</label>
                <input
                    type="text"
                    name="tax_status"
                    id="tax_status"
                    value={props.form.tax_status}
                    onChange={(e) => props.handleChangeForm(e, /[^a-zA-Z0-9]/g)}
                />
            </div>
            <div>
                <label htmlFor="tax_system">Régime fiscal</label>
                <input
                    type="text"
                    name="tax_system"
                    id="tax_system"
                    value={props.form.tax_system}
                    onChange={(e) => props.handleChangeForm(e, /[^a-zA-Z0-9]/g)}
                />
            </div>
            <div>
                <label htmlFor="tva_system">Régime TVA</label>
                <input
                    type="text"
                    name="tva_system"
                    id="tva_system"
                    value={props.form.tva_system}
                    onChange={(e) => props.handleChangeForm(e, /[^a-zA-Z0-9]/g)}
                />
            </div>
        </div>
    )
}